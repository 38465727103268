<template>
  <div>
    <div class="page-container mb-2">
      <h4 class="mb-2">
        Bộ lọc
      </h4>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Năm"
            label-for="year"
          >
            <v-select
              placeholder="Năm"
              :options="dataFilterYear"
              @input="filterByYear"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <button-all-header
        :contentBtnAdd="'Thêm phiên giao dịch'"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        @clickDelete="deleteItems"
        @clickAdd="$router.push({name: 'add-session-employment'})"
        @search="search($event)"
      />
      <vue-good-table
        ref="session-table"
        class="mh-60"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Column -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span class="text-nowrap">
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span
              class="ml-2"
              @click="$router.push({name: 'edit-session-employment', params: {id: props.row.id}})"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
          <span v-else-if="props.column.field === 'code'">
            <b-badge variant="light-primary">
              {{ props.row.code }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'dateSession'">
            {{ props.row.dateSession | formatDate }}
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        @pageClick="handlePageClick"
      />
    </div>
    <confirm-modal
      id="confirm-modal"
      :title="''"
      content="Bạn có chắc chắn muốn xóa phiên giao dịch việc làm không"
      @accept="acceptDelete"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  VBTooltip,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import store from '../store'

export default {
  components: {
    VueGoodTable,
    BBadge,
    MyPagination,
    Search,
    ConfirmModal,
    BButton,
    VBTooltip,
    ButtonAllHeader,
    BFormGroup,
    VSelect,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataFilterYear: [],
      queryParams: {
        year: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã phiên giao dịch',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Ngày thực hiện',
          field: 'dateSession',
          sortable: false,
        },
        {
          label: 'Địa điểm',
          field: 'location',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      showBtnMultiDelete: false,
      deletedIds: [],
      totalRecord: 0,
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },
  created() {
    this.getListSession()
    this.getYear()
    this.dataFilterYear = this.yearList
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    async getListSession() {
      this.$showLoading()
      const { data } = await store.getListSession(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListSession()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListSession()
    },
    filterByYear(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        year: val,
      }
      this.getListSession()
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    deleteItems() {
      this.deletedIds = this.$refs['session-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    async acceptDelete() {
      const res = await store.deleteSession(this.deletedIds)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa phiên giao dịch việc làm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListSession()
      }
    },
  },
}
</script>
