import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  async getListSession(payload) {
    try {
      const { data } = await axiosApiInstance.get('/JobSession/job-sessions', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async addSession(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/add-job-session', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getDetailSession(payload) {
    try {
      const { data } = await axiosApiInstance.get(`/JobSession/job-session/${payload}`)
      return data
    } catch (err) {
      return err
    }
  },
  async editSession(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/update-job-session', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async deleteSession(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/delete-job-session', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getListBusiness(payload) {
    try {
      const { data } = await axiosApiInstance.get('/JobSession/list-job-session-bussiness', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async getListBusinessModal(payload) {
    try {
      const { data } = await axiosApiInstance.get('/JobSession/list-bussiness-not-register', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async addBusinessToSession(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/add-job-session-bussiness', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async deleteBusiness(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/delete-job-session-bussiness', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getListEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.get('/JobSession/list-job-session-worker', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async addEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/add-job-session-worker', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getDetailEmployee(id) {
    try {
      const { data } = await axiosApiInstance.get(`/JobSession/job-session-worker/${id}`)
      return data
    } catch (err) {
      return err
    }
  },
  async editEmployee(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/update-job-session-worker', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async removeEmployeeFromSession(payload) {
    try {
      const { data } = await axiosApiInstance.post('/JobSession/delete-job-session-worker', payload)
      return data
    } catch (err) {
      return err
    }
  },
}
